<template>
  <div class="introduction">
    <div class="header">
      <img src="../../assets/introduction/header.png" alt=""/>
    </div>
    <div class="box">
      <div class="title">
        <div></div>
        <span>平台简介</span>
      </div>
      <!--      <div class="desIconList">-->
      <!--        <div v-for="(item, index) in desIconList" :key="index">-->
      <!--          <img :src="item.img" style="width: 120px;height: 120px;" alt=""/>-->
      <!--          <p>{{ item.text }}</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <el-row :gutter="20">
        <el-col :span="12">
          <video ref="videoPlayer" class="video-content" autoplay loop controls>
            <source
                src="https://outin-99d97c212f7911efb93300163e1c8dba.oss-cn-shanghai.aliyuncs.com/a0e57da32f7971ef85324531858c0102/f46be41b84b8e0ab331fbc7e0d62433c-ld.mp4?OSSAccessKeyId=LTAIVVfYx6D0HeL2&Signature=QLtn2kXRWh6Vwiqtr8liluqsVoY%3D"
                type="video/mp4">
            您的浏览器不支持视频标签。
          </video>
        </el-col>
        <el-col :span="12">
          <div class="serverList">
            <div class="items" v-for="(item, index) in serverList" :key="index">
              <div style="padding: 8px 10px;">
                <img class="circle2" :src="item.img" alt=""/>
              </div>
              <div>
                <div class="title2">{{ item.text }}</div>
                <div class="content">{{ item.content }}</div>
                <div class="content">{{ item.content2 }}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="margin-bottom: 27px;">
        中国-马来西亚互贸创新综合服务平台在真实贸易场景、贸易主体、贸易行为的背景下，通过先进数字化跨境互贸创新服务平台系统，基于星火链网（国家级区块链底层基础设施）快速连接中国及马来西亚政府（工商、税务、海关、法院等机构）、金融机构（银行、保险、第三方支付等），为中马乃至中国-东盟贸易助力，有效实现互联互通、互信共赢。
      </div>
    </div>
    <div class="box">
      <div class="title">
        <div></div>
        <span>科技赋能 · 链接世界 · 创造价值 · 合作共赢</span>
      </div>
      <div class="science">
        <div class="left">
          <img src="../../assets/introduction/science.png" alt=""/>
        </div>
        <div class="right">
          <p>
            <span>链接世界：</span
            >基于可信区块链底层平台，快速链接境内/外各方资源，通过平台实现“快速互联，有的连，连的快”，链上互信提高效率。
          </p>
          <p>
            <span>资源聚合：</span
            >快速聚合境内/外政府端、各级监管机构、金融机构及各类服务商资源，促进跨境产业协同，实现互信共享。
          </p>
          <p>
            <span>数据共享：</span
            >基于可信网络为产业端及金融端提供跨境协同数据共享查询服务，交易对手及授信企业的征信查询、贸易背景查询等服务。
          </p>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="title">
        <div></div>
        <span>为境内 / 外企业提供中、马互贸政策便利</span>
      </div>
      <div class="facilitate">
        <img src="../../assets/introduction/facilitate.png" alt=""/>
      </div>
    </div>
    <div class="box">
      <div class="title">
        <div></div>
        <span>为企业提供国际顶级出口资质认证，提升产品认可度</span>
      </div>
      <div class="approve">
        <p>中国境内首家可以提供马来西亚国际顶级HALAL认证的专业服务平台。</p>
        <div>
          提供国际公认顶级认证标准的准入及认证服务，为企业对马来西亚乃至东盟市场、以及全球清真市场的最高规格准入资质，提高企业境外认可度，帮助企业积极推动相关领域的经济增长。
        </div>
        <div class="setup">
          <div class="setupItem" v-for="(item, index) in setup" :key="index">
            <img :src="item.img" alt=""/>
            <div>{{ item.text1 }}</div>
            <div>{{ item.text2 }}</div>
            <div class="circle"></div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="title">
        <div></div>
        <span>为金融合作伙伴创造价值，合作共赢</span>
      </div>
      <div class="approve">
        <p>基于国家级区块链底层基础设施星火链网。</p>
        <div>
          对接境内/外关、检、汇、税、物流等多渠道数据真实贸易背景下的数据归集实现业务价值。
        </div>
        <div class="imgBox">
          <div class="imgItem" v-for="(item, index) in imgList" :key="index">
            <img :src="item.img" alt=""/>
            <span>{{ item.name }}</span>
            <div>{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      desIconList: [
        {
          img: require("../../assets/introduction/desIcon1.png"),
          text: "贸易便利中心",
        },
        {
          img: require("../../assets/introduction/desIcon2.png"),
          text: "国际认证中心",
        },
        {
          img: require("../../assets/introduction/desIcon3.png"),
          text: "大数据服务中心",
        },
        {
          img: require("../../assets/introduction/desIcon4.png"),
          text: "星火链网海外超级节点",
        },
      ],
      setup: [
        {
          img: require("../../assets/introduction/setup1.png"),
          text1: "协助企业完成资质",
          text2: "申请的准备工作",
        },
        {
          img: require("../../assets/introduction/setup2.png"),
          text1: "为企业组织相关",
          text2: "HALAL认证培训",
        },
        {
          img: require("../../assets/introduction/setup3.png"),
          text1: "邀请HALAL认证考核官",
          text2: "对申请企业进行考核",
        },
        {
          img: require("../../assets/introduction/setup4.png"),
          text1: "协助企业定期维护资质",
          text2: "确保资质的有效性",
        },
        {
          img: require("../../assets/introduction/setup5.png"),
          text1: "基于可信认证资质",
          text2: "提供贷款服务",
        },
      ],
      imgList: [
        {
          img: require("../../assets/introduction/img1.png"),
          name: "客户引流",
          text: "为金融合作伙伴提供企业客户引流，实现线上开户。",
        },
        {
          img: require("../../assets/introduction/img2.png"),
          name: "需求对接",
          text: "面向企业提供金融服务的窗口，实现结算、融资需求对接。",
        },
        {
          img: require("../../assets/introduction/img3.png"),
          name: "数据共享",
          text: "多方可信数据汇集节点，为金融服务端提供数据支撑。",
        },
        {
          img: require("../../assets/introduction/img4.png"),
          name: "交易落地",
          text: "实现中马互贸业务的全流程线上化，提升业务效率。",
        },
      ],
      serverList: [
        {
          img: require("../../assets/home/icon1.png"),
          text: "政策便利",
          content: "中国企业出海",
          content2: "马来西亚企业来华投资"
        },
        {
          img: require("../../assets/home/icon3.png"),
          text: "金融便利",
          content: "跨境贸易融资",
          content2: "境内外银行/支付账户开立"
        },
        {
          img: require("../../assets/home/icon2.png"),
          text: "贸易便利",
          content: "中马精选商品展厅",
          content2: "中马贸易商机撮合"
        },
        {
          img: require("../../assets/home/icon4.png"),
          text: "认证便利",
          content: "国际清真认证",
          content2: "国际企业信用认证"
        },
        {
          img: require("../../assets/home/icon5.png"),
          text: "区块链支撑",
          content: "国家级区块链新基建",
          content2: "产融协同新体系"
        },
        {
          img: require("../../assets/home/icon6.png"),
          text: "合作共赢",
          content: "加快培育外贸新动能",
          content2: "巩固外贸外资基本盘"
        },
      ],
    };
  },
  mounted() {
    this.playVideo();
  },
  methods: {
    playVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.muted = false; // 确保视频开始时未静音
      videoPlayer.volume = 0.5; // 设置视频音量 (0 - 1 之间)
      videoPlayer.play().catch(error => console.log("Video play failed:", error));
    }
  }
};
</script>

<style lang="scss" scoped>
.introduction {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #000000;
}

.header {
  background: #2a50b7;

  img {
    display: block;
    margin: 0 auto;
  }
}

.box {
  width: 1080px;
  margin: 0 auto;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    margin-top: 60px;

    div {
      width: 6px;
      height: 37px;
      background: #2a50b7;
      margin-right: 8px;
    }

    span {
      font-size: 33px;
      line-height: 43px;
    }
  }

  .desIconList {
    margin-top: 41px;
    padding: 0 166px;
    display: flex;
    justify-content: space-between;
    text-align: center;

    img {
      display: block;
    }

    p {
      line-height: 43px;
      color: #2a50b7;
    }
  }

  .science {
    display: flex;

    img {
      display: block;
      margin-right: 16px;
    }

    span {
      color: #2a50b7;
      font-weight: 600;
    }

    p {
      margin-bottom: 30px;
    }
  }

  .facilitate {
    img {
      display: block;
    }
  }

  .approve {
    p {
      color: #2a50b7;
      font-weight: 600;
    }

    .setup {
      margin-top: 30px;
      display: flex;
      padding: 0 120px;
      text-align: center;
      position: relative;

      .setupItem {
        width: 158px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .circle {
          width: 14px;
          height: 14px;
          background: #dddddd;
          border-radius: 50%;
          position: absolute;
          top: 63px;
        }
      }

      img {
        display: block;
        margin-bottom: 30px;
      }

      div {
        font-size: 14px;
        line-height: 16px;
        color: #666666;
      }

      .line {
        position: absolute;
        width: 1088px;
        height: 4px;
        background: #dddddd;
        left: 0;
        top: 68px;
      }
    }

    .imgBox {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 160px;

      .imgItem {
        width: 260px;
        height: 251px;
        padding: 30px;
        border: 1px solid #dddddd;
        border-radius: 11px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          display: block;
        }

        span {
          font-size: 24px;
          color: #666666;
          margin-top: 5px;
        }

        div {
          margin-top: 10px;
          padding-top: 15px;
          width: 199px;
          font-size: 14px;
          line-height: 22px;
          color: #666666;
          border-top: 1px solid #DDDDDD;
        }
      }
    }
  }
}

.serverList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .items {
    flex: 0 0 49%;
    display: flex;
    align-items: center;
    margin-bottom: 26px;
  }

  .title2 {
    font-weight: 600;
    font-size: 18px;
    color: #306FC7;
    line-height: 28px;
  }

  .content {
    font-weight: 400;
    font-size: 13px;
    color: #444444;
    line-height: 22px;
  }

  .circle2 {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    padding: 15px;
    border: solid 1px #5A9CF8;
  }
}

.video-content {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
}
</style>
