<template>
  <div class="adminLayout">
    <div class="menuBox">
      <el-menu
        :default-active="active"
        class="leftMenu"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
      >
        <div class="title">运营平台</div>
        <template v-for="(item, index) in menuList">
          <el-submenu v-if="item.children" :index="item.id">
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <el-menu-item
              :index="i.id"
              v-for="(i, id) in item.children"
              :key="id"
              @click="toUrl(i.path)"
              >{{ i.title }}</el-menu-item
            >
          </el-submenu>
          <template v-else>
            <el-menu-item :index="item.id"
              ><i :class="item.icon" @click="toUrl(item.path)"></i
              >{{ item.title }}</el-menu-item
            >
          </template>
        </template>
      </el-menu>
    </div>
    <div class="content">
      <div class="header">
        <div class="left">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              v-for="(item, index) in breadcrumb"
              :key="index"
              >{{ item }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <div class="right">
          <div class="name">
            <span>{{ user.name }}</span> 你好
          </div>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-menu"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>修改密码</el-dropdown-item>
              <el-dropdown-item>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          id: "1",
          title: "用户",
          icon: "el-icon-user-solid",
          children: [
            {
              id: "11",
              title: "用户管理",
              path: "/user",
            },
          ],
        },
        {
          id: "2",
          title: "产品",
          icon: "el-icon-menu",
          children: [
            {
              id: "21",
              title: "产品管理",
              path: "/product",
            },
          ],
        },
      ],
      user: {
        name: "admin",
      },
      breadcrumb: [],
      active: "",
    };
  },
  created() {
    this.breadcrumb = this.$route.meta.name;
    this.menuList.forEach((item) => {
      if (item.children) {
        item.children.forEach((i) => {
          if (i.path == this.$route.fullPath) {
            this.active = i.id;
          }
        });
      } else {
        if (item.path == this.$route.fullPath) {
          this.active = item.id;
        }
      }
    });
  },
  methods: {
    toUrl(val) {
      if (this.$route.fullPath != val) {
        this.$router.push(val);
        this.breadcrumb = this.$route.meta.name;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.adminLayout {
  position: relative;
}
.menuBox {
  position: absolute;
}
.leftMenu {
  height: 100vh;
  width: 200px;
  border: none;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  .menuLine {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
::v-deep .el-menu {
  width: 200px;
  .title {
    width: 200px;
    line-height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    background: #545c64;
  }
  .el-submenu,
  .el-menu-item {
    width: 200px;
  }
}
.content {
  padding-left: 200px;
}
.header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  .left {
  }
  .right {
    display: flex;
    align-items: center;
    .name {
      span {
        color: #66b1ff;
      }
    }
    .el-dropdown {
      cursor: pointer;
      padding-left: 16px;
      .el-icon-menu {
        font-size: 16px;
      }
    }
  }
}
.main {
  padding: 25px;
}
</style>