export default {
  http: {
    //baseUrl: process.env.NODE_ENV == "/pc/", process.env.NODE_ENV用于区分是生产环境还是开发环境
    baseUrl: '/api',
    timeOut: 600000, //超时时间
    isDev: process.env.NODE_ENV == "development" ? "Y" : "N", //是否开发环境
    test: 'test/' // 测试
  },
  system: {
    name: "name"
  }
};
