<template>
  <div class="loginPage">
    <div class="des">
      <img src="../../assets/login/bg.png" alt="" />
      <div>中马互贸</div>
      <p>
        中国-马来西亚互贸创新综合服务平台由青岛市市南区政府主办，整合中马核心企业以及多家金融机构，协同马来西亚政府共同推进“中马互贸创新示范园”建设工作，在真是贸易场景、贸易主体、贸易行为的背景下，基于星火链网快速连接中马双方资源，有效实现互联互通，互信共赢。
      </p>
    </div>
    <div class="main">
      <div class="loginBox">
        <div class="title">
          <div>中国 - 马来西亚</div>
          <p>互贸创新综合服务平台</p>
        </div>
        <el-form ref="login" :model="adminLogin">
          <p class="lable">账号</p>
          <el-form-item prop="account">
            <el-input
              v-model="adminLogin.account"
              placeholder="账号"
            ></el-input>
          </el-form-item>
          <p class="lable">密码</p>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="adminLogin.password"
              placeholder="密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn" @click="loginClick">登录</div>
      </div>
    </div>
    <el-dialog
      class="dialogError"
      title="提示"
      :visible.sync="dialogError"
      width="570px"
      :show-close="false"
    >
      <p>账号或密码错误，请重试！</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogError = false">取 消</el-button>
        <el-button class="sure" @click="dialogError = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialogError"
      title="提示"
      :visible.sync="dialogEmpty"
      width="570px"
      :show-close="false"
    >
      <p>请输入账号和密码！</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEmpty = false">取 消</el-button>
        <el-button class="sure" @click="dialogEmpty = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialogSuccess"
      :visible.sync="dialogSuccess"
      width="418px"
      :show-close="false"
    >
      <img src="../../assets/login/success.png" alt="" />
      <p>登录成功</p>
      <p>正在进入工作台</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adminLogin: {
        account: "",
        password: "",
      },
      dialogError: false,
      dialogSuccess: false,
      dialogEmpty: false,
    };
  },
  methods: {
    loginClick() {
      if (!this.adminLogin.account || !this.adminLogin.password) {
        this.dialogEmpty = true;
        return;
      }
      if (this.adminLogin.account != this.adminLogin.password) {
        this.dialogError = true;
        return;
      }
      this.dialogSuccess = true;
      setTimeout(() => {
        this.dialogSuccess = false;
        this.$router.push("/adminLayout");
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.loginPage {
  font-weight: 200;
  display: flex;
}
img {
  display: block;
}
.des {
  position: relative;
  div {
    position: absolute;
    font-size: 66px;
    font-weight: 800;
    line-height: 92px;
    color: #20429d;
    left: 148px;
    top: 170px;
  }
  p {
    line-height: 43px;
    color: #ffffff;
    position: absolute;
    left: 148px;
    top: 274px;
    width: 354px;
  }
}
.main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginBox {
  .title {
    div {
      font-size: 27px;
      line-height: 61px;
    }
    p {
      font-size: 44px;
      line-height: 61px;
    }
  }
  .lable {
    line-height: 22px;
    color: #666666;
    margin-top: 11px;
    margin-bottom: 22px;
  }
  ::v-deep .el-input {
    .el-input__inner {
      width: 452px;
      border: 1px solid #999999;
      border-radius: 12px;
      height: 62px;
      font-size: 26px;
      line-height: 43px;
      font-weight: 200;
      opacity: 1;
    }
    .el-input__inner::placeholder {
      color: #dddddd;
    }
  }
  .btn {
    width: 452px;
    line-height: 62px;
    background: #63c918;
    border: 1px solid #dddddd;
    border-radius: 31px;
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
  }
  .agree {
    margin-top: 24px;
    display: flex;
    align-items: center;
    ::v-deep .el-checkbox {
      margin-right: 6px;
      .el-checkbox__inner {
        width: 15px;
        height: 15px;
        border-radius: 4px;
      }
    }
    p {
      font-size: 14px;
      line-height: 19px;
      span {
        color: #2a50b7;
        cursor: pointer;
      }
    }
  }
}
::v-deep .el-form-item__error {
  font-size: 14px;
}
::v-deep .is-error {
  .el-input__inner {
    border: 1px solid #dd4040 !important;
  }
}
.dialog-footer {
  .el-button {
    border: 1px solid #999999;
    border-radius: 10px;
    font-weight: 200;
    color: #000000;
  }
  .sure {
    background: #63c918;
    border: none;
    color: #ffffff;
  }
}
.dialogAgree {
  p {
    span {
      color: #2a50b7;
    }
  }
}
::v-deep .dialogSuccess {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 58px 118px 75px;
  }
  img {
    margin: 0 auto;
  }
  p {
    font-size: 26px;
    font-weight: 200;
    line-height: 43px;
    color: #2a50b7;
    text-align: center;
  }
}
.register {
  .title {
    margin-bottom: 22px;
    p {
      font-size: 44px;
      line-height: 61px;
    }
  }
  p {
    line-height: 30px;
  }
  .inputBox {
    width: 420px;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 24px;
    input {
      width: 60px;
      font-size: 44px;
      font-weight: 200;
      line-height: 80px;
      color: #000000;
      text-align: center;
      border: 1px solid #2a50b7;
      border-radius: 12px;
    }
    .codeError {
      border: 1px solid #dd4040;
    }
  }
  .backBox {
    display: flex;
    font-size: 14px;
    line-height: 30px;
    color: #dd4040;
    justify-content: space-between;
  }
  .back {
    font-size: 14px;
    line-height: 30px;
    color: #2a50b7;
    cursor: pointer;
  }
  .try {
    line-height: 30px;
    color: #2a50b7;
    cursor: pointer;
  }
}
.setPassword,
.loginPassword {
  .title {
    div {
      font-size: 27px;
      line-height: 61px;
    }
    p {
      font-size: 44px;
      line-height: 61px;
    }
  }
  .user {
    display: inline-block;
    padding: 10px 25px 10px 15px;
    background: #f6f6f6;
    border-radius: 12px;
    margin-top: 15px;
    div {
      display: flex;
      align-items: center;
      span {
        font-size: 22px;
        line-height: 31px;
        color: #000000;
      }
    }
  }
  .tips {
    display: flex;
    align-items: center;
    padding: 10px 25px 10px 15px;
    background: #f6f6f6;
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 9px;
    }
    span {
      line-height: 22px;
      color: #666666;
    }
  }
  .lable {
    line-height: 22px;
    color: #666666;
    margin-top: 11px;
    margin-bottom: 22px;
  }
  ::v-deep .el-input {
    .el-input__inner {
      width: 452px;
      border: 1px solid #999999;
      border-radius: 12px;
      height: 62px;
      font-size: 26px;
      line-height: 43px;
      font-weight: 200;
      opacity: 1;
    }
    .el-input__inner::placeholder {
      color: #dddddd;
    }
  }
  .btn {
    width: 452px;
    line-height: 62px;
    background: #63c918;
    border: 1px solid #dddddd;
    border-radius: 31px;
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
  }
  .disabled {
    background: #dddddd;
    color: #ffffff;
    cursor: not-allowed;
  }
  .back {
    font-size: 14px;
    line-height: 30px;
    color: #2a50b7;
    cursor: pointer;
    margin-top: 20px;
  }
  .backBox {
    display: flex;
    justify-content: space-between;
    p {
      margin-top: 20px;
      line-height: 22px;
      color: #666666;
      span {
        color: #2a50b7;
        cursor: pointer;
      }
    }
  }
}
</style>