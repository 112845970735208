import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '../components/Layout.vue' // 布局
import Home from '../views/Home.vue' // 首页
import Introduction from '../views/staticPage/Introduction.vue' // 平台介绍
import Guide from '../views/staticPage/Guide.vue' // 业务指南
import NewsInfo from '../views/staticPage/newsInfo.vue' // 业务指南
import Technology from '../views/staticPage/Technology.vue' // 技术赋能
import ContactUs from '../views/staticPage/ContactUs.vue' // 联系我们

import Login from '../views/login/Login.vue' // 客户登录

import AdminLogin from '../views/login/AdminLogin.vue' // 客户登录
import AdminLayout from '../components/AdminLayout.vue' // 后台布局
import User from '../views/adminManage/User.vue' // 用户管理
import Product from '../views/adminManage/Product.vue' // 产品管理

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: "/home",
                component: Home,
                meta: {
                    content: "中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
                }
            },
            {
                path: "/introduction",
                component: Introduction,
                meta: {
                    content: "中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
                }
            },
            {
                path: "/guide",
                component: Guide,
                meta: {
                    content: "中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
                }
            },
            {
                path: "/newsInfo",
                component: NewsInfo,
                meta: {
                    content: "中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
                }
            },
            {
                path: "/technology",
                component: Technology,
                meta: {
                    content: "中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
                }
            },
            {
                path: "/contactUs",
                component: ContactUs,
                meta: {
                    content: "中国马来西亚互贸创新综合服务平台,中马互贸创新综合服务平台,FDI,NRA,跨境贸易融资,中马互贸平台,中马互贸,跨境贸易,跨境金融,跨境贸易系统,跨境金融系统,DIXchain,智链,智链优创,区块链,Cross-border trade, cross-border finance, cross-border trading system, cross-border financial system,DIXchain,blockchain,星火链网,zetrix,区块链金融"
                }
            },
        ]
    },
    // {
    //   path: '/login',
    //   component: Login,
    // },
    // {
    //   path: '/adminLogin',
    //   component: AdminLogin,
    // },
    // {
    //   path: '/adminLayout',
    //   component: AdminLayout,
    //   redirect: '/user',
    //   children: [
    //     {
    //       path: '/user',
    //       component: User,
    //       meta: {
    //         name: ["用户",'用户管理'],
    //       },
    //     },
    //     {
    //       path: '/product',
    //       component: Product,
    //       meta: {
    //         name: ["产品",'产品管理'],
    //       },
    //     },
    //   ]
    // }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (window._hmt) {
        if (to.path) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
        }
    }
    next();
});

export default router
