import axios from "axios";
import config from "@/utils/config";
// import Cookies from "js-cookie";
import { Loading, Message, MessageBox } from "element-ui";

let loadinginstace;
axios.defaults.timeout = 15000;
axios.defaults.baseURL = config.http.baseUrl;
// let token =  Cookies.get("Authorization") || "";
// console.log(config.http.baseUrl)
//请求前拦截
let status = false;
axios.interceptors.request.use(
  config => {
    if (loadinginstace && loadinginstace.visible && !status) {
      return config;
    }
    else {
      status = true;
      loadinginstace = Loading.service({
        target: ".content-container",
        lock: true,
        text: "加载中..."
      });
    }
    return config;
  },
  error => {
    if (loadinginstace) {
      loadinginstace.close();
      status = false;
    }
    return Promise.reject("请求超时");
  }
);
//设置response统一处理设置response统一处理
axios.interceptors.response.use(
  response => {
    if (loadinginstace) {
      status = false;
      loadinginstace.close();
    }
    if (response.status == 200) {
      if (response.data.code != '-1') {
        return Promise.resolve(response.data);
      } else if (response.data.code == '-1') {
        // MessageBox.alert('您的登录已过期，请重新登录!', '提示', {
        //   confirmButtonText: '确定',
        //   showClose: false,
        //   closeOnClickModal: false,
        //   callback: action => {
        //     Cookies.get("isAdmin") == 'true' ? location.href = "/#/adminLogin" : location.href = "/"
        //   }
        // })
      }
    } else {
      //数据失败直接reject
      Message.error({ message: "系统繁忙" });
      return Promise.reject("系统繁忙");
    }
  },
  error => {
    //http失败
    if (loadinginstace) {
      loadinginstace.close();
      status = false;
    }
    Message.error({ message: "系统繁忙" });
    return Promise.reject("系统繁忙");
    // switch (error.response.status) {
    //     // case 403: location.href = "/"; return;
    //     // case 401: location.href = "/"; return;
    //     // case 404:
    //     //     return Promise.reject("code404");
    //     // case 500:
    //     //     return Promise.reject("code500");
    //     // case 504:
    //     //     return Promise.reject("code404");
    //     // case 502:
    //     //     return Promise.reject("code502");
    //     default:
    //         return Promise.reject('error.response.data.msg[0]');
    // }
  }
);
export default {
  /**
   * 发送post请求(form表单)
   * @param {string} url 地址
   * @param {object} data 请求数据
   * @param {Function} done 成功回调
   * @param {Function} fail 失败回调(可选)
   */
  post(url, data, done, fail) {
    let token = sessionStorage.getItem("token") || "";
    return axios({
      method: "post",
      url,
      // data: qs.stringify(data),
      data: data,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        //'Content-Type': 'multipart/form-data',
        "Content-Type": "application/json;charset=UTF-8",
        "Authorization": token
      }
    })
      .then(data => done(data))

      .catch(error => {
        console.log(error)
      });
  },

  postBody(url, data, done, fail) {
    return axios
      .post(url, data)
      .then(data => done(data))
      .catch(error => {
        console.log(error)
      });
  },

  get(url, ...options) {
    let token = sessionStorage.getItem("token") || "";
    let params, done, fail;
    if (typeof options[0] === "object") {
      params = options[0];
      done = options[1];
      fail = options[2];
    } else {
      params = {};
      done = options[0];
      fail = options[1];
    }
    return axios({
      method: "get",
      url,
      params, // get 请求时带的参数
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        'token': token
      }
    })
      .then(data => done(data))
      .catch(error => {
        console.log(error)
      });
  },
};
