<template>
  <div class="newsInfo">
    <div class="header">
      <img src="../../assets/guide/header.png" alt=""/>
    </div>
    <div class="tabsBox">
      <div class="content">
        <div class="title">
          <span @click="back()"><i class="el-icon-arrow-left"></i>返回</span>
          <p>{{ news.webTitle }}</p>
        </div>
        <div style="width:100%;height: 30px; ">
          <span>发布时间：</span>
          <span> {{ news.newsTime }}</span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span>来源：</span>
          <span> {{ news.newsSource }}</span>
        </div>
        <div style="border-bottom: 1px solid #dddddd;margin-top: 20px;margin-bottom: 20px; "></div>
        <div v-html="news.context"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'newsInfo',
  data() {
    return {
      news: {}
    };
  },
  created() {
    if (localStorage.getItem('news')) {
      // 从 localStorage 中获取数据
      const storedData = localStorage.getItem('news');
      // 解析 JSON 字符串
      const parsedData = JSON.parse(storedData);
      // 如果数据存在，可以将其赋值给组件的数据
      if (parsedData) {
        this.news = parsedData;
      }
    } else if (this.$route.query.news) {
      this.news = this.$route.query.news;
      window.localStorage.setItem('news', JSON.stringify(this.news))
    }
  },
  methods: {
    back() {
      this.$router.push({path: '/'});
    },
  },
};
</script>

<style lang="scss" scoped>
.newsInfo {
  font-weight: 400;
}

.pageStyle {
  margin-top: 20px;
  text-align: center;
}

img {
  display: block;
}

.header {
  background: #2a50b7;

  img {
    margin: 0 auto;
  }
}

.tabsBox {
  width: 1350px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 100px;
  display: flex;

  .tabs {
    margin-right: 16px;

    .tabsItem {
      width: 260px;
      height: 80px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      div {
        width: 15px;
        height: 80px;
        background: #dddddd;
        margin-right: 29px;
      }

      p {
        font-size: 20px;
        line-height: 33px;
        color: #999999;
      }
    }

    .active {
      background: #f6f6f6;

      div {
        background: #2a50b7;
      }

      p {
        color: #666666;
      }
    }
  }
}

.listBox,
.content {
  width: 1088px;
}

.content {
  min-height: 700px;
  border: 1px solid #dddddd;
  padding: 0 100px;
  padding-bottom: 70px;
  font-size: 16px;
  line-height: 32px;
  margin: 0 auto;

  .title {
    position: relative;

    span {
      position: absolute;
      left: -90px;
      top: 10px;
      font-size: 20px;
      color: #999;
      cursor: pointer;
    }

    p {
      font-size: 33px;
      line-height: 46px;
      color: #2a50b7;
      padding-top: 35px;
      text-align: center;

      margin-bottom: 30px;
    }
  }
}

.list {
  padding: 37px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dddddd;

  .left {
    margin-right: 33px;
    margin-left: 25px;
    cursor: pointer;
  }

  .right {
    cursor: pointer;

    p {
      font-size: 24px;
      line-height: 33px;
      color: #2a50b7;
    }

    span {
      font-size: 16px;
      line-height: 33px;
      color: #999999;
    }
  }
}
</style>
