const validateAccount = (rule, value, callback) => {
  let phone = new RegExp("^[1][0-9]{10}$"); // 手机号
  let email = new RegExp("^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*.[a-zA-Z0-9]{2,6}$")
  if (value !== "" && !(phone.test(value) || email.test(value))) {
    return callback(new Error("请输入正确格式的手机号/邮箱"));
  } else {
    callback();
  }
};
export default {
  login: {
    account: [
      {
        required: true,
        message: "请输入手机号/邮箱",
        trigger: "blur"
      },
      {
        validator: validateAccount,
        trigger: "blur"
      }
    ]
  },
  productForm: {
    name: [
      {
        required: true,
        message: " ",
        trigger: "blur"
      },
    ],
    type: [
      {
        required: true,
        message: " ",
        trigger: "change"
      },
    ],
    des: [
      {
        required: true,
        message: " ",
        trigger: "blur"
      },
    ],
    state: [
      {
        required: true,
        message: " ",
        trigger: "change"
      }
    ]
  }
}