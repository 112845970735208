import http from "@/utils/http";
import config from "@/utils/config";

export default {
  testFun(opt, success) {
    http.post(
      config.http.test + 
      // "test",
      opt,
      success,
    )
  },
};