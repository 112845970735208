<template>
  <div class="loginPage">
    <div class="des">
      <img src="../../assets/login/bg.png" alt="" />
      <div>中马互贸</div>
      <p>
        中国-马来西亚互贸创新综合服务平台由青岛市市南区政府主办，整合中马核心企业以及多家金融机构，协同马来西亚政府共同推进“中马互贸创新示范园”建设工作，在真是贸易场景、贸易主体、贸易行为的背景下，基于星火链网快速连接中马双方资源，有效实现互联互通，互信共赢。
      </p>
    </div>
    <div class="main">
      <div class="loginBox" v-if="step == 1">
        <div class="title">
          <div>中国 - 马来西亚</div>
          <p>互贸创新综合服务平台</p>
        </div>
        <p class="lable">手机号 / 邮箱</p>
        <el-form ref="login" :model="login">
          <el-form-item prop="account">
            <el-input
              v-model="login.account"
              placeholder="手机号或邮箱"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn" @click="loginClick">登录 / 注册</div>
        <div class="agree">
          <el-checkbox v-model="agree"></el-checkbox>
          <p>我已阅读并同意 <span>服务协议</span> 和 <span>隐私协议</span></p>
        </div>
      </div>
      <div class="register" v-if="step == 2">
        <div class="title">
          <p>欢迎入驻</p>
        </div>
        <p>
          请输入发送至
          <span>{{ login.account }}</span> 的6位验证码，有效期15分钟。
        </p>
        <p v-if="time != 0">{{ time }}秒后可尝试重新获取验证码</p>
        <span v-else class="try" @click="getCode">重新获取验证码</span>
        <div class="inputBox">
          <div v-for="(item, index) in inputList" :key="index">
            <input
              type="type"
              v-model="item.pinless"
              :class="codeError ? 'inputValue codeError' : 'inputValue'"
              @keyup="keyboard($event, index)"
              maxlength="1"
            />
          </div>
        </div>
        <p class="backBox">
          <span class="back" @click="back">< 返回上一步</span
          ><span v-if="codeError">验证码输入错误，请重新输入</span>
        </p>
      </div>
      <div class="setPassword" v-if="step == 3">
        <div class="title">
          <p>设置密码</p>
        </div>
        <div class="user">
          <div>
            <img src="../../assets/login/user.png" alt="" />
            <span>{{ this.login.account }}</span>
          </div>
        </div>
        <div class="tips">
          <img src="../../assets/login/warning.png" alt="" />
          <span>需设置6～18位数字和字母组合密码</span>
        </div>
        <el-form :model="login" status-icon :rules="rules" ref="password">
          <p class="lable">设置登录密码</p>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="login.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <p class="lable">再次输入密码</p>
          <el-form-item prop="checkPass">
            <el-input
              type="password"
              v-model="login.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn" @click="setPassword">确定</div>
        <p class="backBox">
          <span class="back" @click="back">< 返回上一步</span>
        </p>
      </div>
      <div class="loginPassword" v-if="step == 4">
        <div class="title">
          <p>欢迎回来</p>
        </div>
        <div class="user">
          <div>
            <img src="../../assets/login/user.png" alt="" />
            <span>{{ this.login.account }}</span>
          </div>
        </div>
        <el-form :model="login" status-icon>
          <p class="lable">输入登录密码</p>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="login.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div
          :class="login.password.length > 5 ? 'btn' : 'btn disabled'"
          @click="loginPassword"
        >
          确定
        </div>
        <div class="backBox">
          <span class="back" @click="back">< 返回上一步</span>
          <p>忘记密码？<span @click="reSet">重置密码</span></p>
        </div>
      </div>
      <div class="register" v-if="step == 5">
        <div class="title">
          <p>找回密码</p>
        </div>
        <p>
          请输入发送至
          <span>{{ login.account }}</span> 的6位验证码，有效期15分钟。
        </p>
        <p v-if="time != 0">{{ time }}秒后可尝试重新获取验证码</p>
        <span v-else class="try" @click="getCode">重新获取验证码</span>
        <div class="inputBox">
          <div v-for="(item, index) in inputList" :key="index">
            <input
              type="type"
              v-model="item.pinless"
              :class="codeError ? 'inputValue codeError' : 'inputValue'"
              @keyup="keyboard1($event, index)"
              maxlength="1"
            />
          </div>
        </div>
        <p class="backBox">
          <span class="back" @click="back">< 返回上一步</span
          ><span v-if="codeError">验证码输入错误，请重新输入</span>
        </p>
      </div>
      <div class="setPassword" v-if="step == 6">
        <div class="title">
          <p>设置新密码</p>
        </div>
        <div class="user">
          <div>
            <img src="../../assets/login/user.png" alt="" />
            <span>{{ this.login.account }}</span>
          </div>
        </div>
        <div class="tips">
          <img src="../../assets/login/warning.png" alt="" />
          <span>需设置6～18位数字和字母组合密码</span>
        </div>
        <el-form :model="login" status-icon :rules="rules" ref="password">
          <p class="lable">设置登录密码</p>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="login.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <p class="lable">再次输入密码</p>
          <el-form-item prop="checkPass">
            <el-input
              type="password"
              v-model="login.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="btn" @click="setPassword">确定</div>
        <p class="backBox">
          <span class="back" @click="back">< 返回上一步</span>
        </p>
      </div>
    </div>
    <el-dialog
      class="dialogAgree"
      title="提示"
      :visible.sync="dialogAgree"
      width="570px"
      :show-close="false"
    >
      <p>
        点击同意，表示您已阅读并同意 <span>服务协议</span> 和
        <span>服务协议</span>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAgree = false">取 消</el-button>
        <el-button class="sure" @click="dialogAgree = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialogError"
      title="提示"
      :visible.sync="dialogError"
      width="570px"
      :show-close="false"
    >
      <p>请输入正确的手机号码或邮箱地址。</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogError = false">取 消</el-button>
        <el-button class="sure" @click="dialogError = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialogSuccess"
      :visible.sync="dialogSuccess"
      width="418px"
      :show-close="false"
    >
      <img src="../../assets/login/success.png" alt="" />
      <p>登录成功</p>
      <p>正在进入工作台</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    let validatePass = (rule, value, callback) => {
      let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/; // 6～18位数字和字母组合密码
      console.log(regex.test(value));
      if (value == "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6) {
        callback(new Error("密码不能小于6位，请您重新输入"));
      } else if (value.length > 18) {
        callback(new Error("密码最大长度为18位，请您重新输入"));
      } else if (!regex.test(value)) {
        callback(new Error("请设置6～18位数字和字母组合密码"));
      } else {
        if (this.login.checkPass !== "") {
          this.$refs.password.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.login.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      login: {
        account: "18888888888",
        code: "888888",
        password: "",
        checkPass: "",
      },
      agree: true,
      dialogAgree: false,
      dialogError: false,
      dialogSuccess: false,
      isNew: false,
      step: 1,
      inputList: [
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
      ],
      time: 0,
      codeError: false,
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    reSet() {
      this.login.password = "";
      this.login.checkPass = "";
      this.step = 5;
      this.time = 10;
      let timer = setInterval(() => {
        this.time--;
        if (this.time == 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
    loginPassword() {
      if (this.login.password.length < 6) return;
      console.log(this.login);
      this.dialogSuccess = true;
      setTimeout(() => {
        this.dialogSuccess = false;
        this.$router.push("/adminLayout");
      }, 500);
    },
    setPassword() {
      let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,18}$/;
      if (
        regex.test(this.login.password) &&
        this.login.password == this.login.checkPass
      ) {
        console.log(this.login);
        this.dialogSuccess = true;
        setTimeout(() => {
          this.dialogSuccess = false;
          this.$router.push("/adminLayout");
        }, 500);
      }
    },
    loginClick() {
      this.login.password = "";
      this.login.checkPass = "";
      this.inputList = [
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
      ];
      let phone = new RegExp("^[1][0-9]{10}$"); // 手机号
      let email = new RegExp(
        "^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*.[a-zA-Z0-9]{2,6}$"
      ); // 邮箱
      if (!(phone.test(this.login.account) || email.test(this.login.account))) {
        this.dialogError = true;
        return;
      }
      if (!this.agree) {
        this.dialogAgree = true;
        return;
      }
      if (this.isNew) {
        this.step = 2;
        this.time = 10;
        let timer = setInterval(() => {
          this.time--;
          if (this.time == 0) {
            clearInterval(timer);
          }
        }, 1000);
      } else {
        this.step = 4;
      }
    },
    keyboard(e, index) {
      console.log(e, index);
      let domNode = document.getElementsByClassName("inputValue"),
        currInput = domNode[index],
        nextInput = domNode[index + 1],
        lastInput = domNode[index - 1];
      if (
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        (e.keyCode >= 65 && e.keyCode <= 90)
      ) {
        let str = "";
        this.inputList.forEach((item) => {
          str += item.pinless;
        });
        if (str.length == 6) {
          currInput.blur();
          console.log(str);
          if (str == this.login.code) {
            this.codeError = false;
            this.step = 3;
          } else {
            this.codeError = true;
          }
          return;
        }
        if (index < this.inputList.length - 1) {
          nextInput.focus();
        } else {
          currInput.blur();
        }
      } else if (e.keyCode == 8 && index > 0) {
        lastInput.focus();
      } else {
        this.inputList.forEach((item, id) => {
          if (id == index) {
            item.pinless = "";
          }
        });
      }
    },
    keyboard1(e, index) {
      console.log(e, index);
      let domNode = document.getElementsByClassName("inputValue"),
        currInput = domNode[index],
        nextInput = domNode[index + 1],
        lastInput = domNode[index - 1];
      if (
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        (e.keyCode >= 65 && e.keyCode <= 90)
      ) {
        let str = "";
        this.inputList.forEach((item) => {
          str += item.pinless;
        });
        if (str.length == 6) {
          currInput.blur();
          console.log(str);
          if (str == this.login.code) {
            this.codeError = false;
            this.step = 6;
          } else {
            this.codeError = true;
          }
          return;
        }
        if (index < this.inputList.length - 1) {
          nextInput.focus();
        } else {
          currInput.blur();
        }
      } else if (e.keyCode == 8 && index > 0) {
        lastInput.focus();
      } else {
        this.inputList.forEach((item, id) => {
          if (id == index) {
            item.pinless = "";
          }
        });
      }
    },
    expurgate(index) {
      this.inputList[index].pinless = "";
    },
    back() {
      this.login.password = "";
      this.login.checkPass = "";
      this.inputList = [
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
        { pinless: "" },
      ];
      if (this.step == 4 || this.step == 2) {
        this.login.account = "";
      }
      if (this.step == 4) {
        this.step = 1;
      } else {
        this.step--;
      }
    },
    getCode() {
      this.time = 10;
      let timer = setInterval(() => {
        this.time--;
        if (this.time == 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.loginPage {
  font-weight: 200;
  display: flex;
}
img {
  display: block;
}
.des {
  position: relative;
  div {
    position: absolute;
    font-size: 66px;
    font-weight: 800;
    line-height: 92px;
    color: #20429d;
    left: 148px;
    top: 170px;
  }
  p {
    line-height: 43px;
    color: #ffffff;
    position: absolute;
    left: 148px;
    top: 274px;
    width: 354px;
  }
}
.main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginBox {
  .title {
    div {
      font-size: 27px;
      line-height: 61px;
    }
    p {
      font-size: 44px;
      line-height: 61px;
    }
  }
  .lable {
    line-height: 22px;
    color: #666666;
    margin-top: 11px;
    margin-bottom: 22px;
  }
  ::v-deep .el-input {
    .el-input__inner {
      width: 452px;
      border: 1px solid #999999;
      border-radius: 12px;
      height: 62px;
      font-size: 26px;
      line-height: 43px;
      font-weight: 200;
      opacity: 1;
    }
    .el-input__inner::placeholder {
      color: #dddddd;
    }
  }
  .btn {
    width: 452px;
    line-height: 62px;
    background: #63c918;
    border: 1px solid #dddddd;
    border-radius: 31px;
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
  }
  .agree {
    margin-top: 24px;
    display: flex;
    align-items: center;
    ::v-deep .el-checkbox {
      margin-right: 6px;
      .el-checkbox__inner {
        width: 15px;
        height: 15px;
        border-radius: 4px;
      }
    }
    p {
      font-size: 14px;
      line-height: 19px;
      span {
        color: #2a50b7;
        cursor: pointer;
      }
    }
  }
}
::v-deep .el-form-item__error {
  font-size: 14px;
}
::v-deep .is-error {
  .el-input__inner {
    border: 1px solid #dd4040 !important;
  }
}
.dialog-footer {
  .el-button {
    border: 1px solid #999999;
    border-radius: 10px;
    font-weight: 200;
    color: #000000;
  }
  .sure {
    background: #63c918;
    border: none;
    color: #ffffff;
  }
}
.dialogAgree {
  p {
    span {
      color: #2a50b7;
    }
  }
}
::v-deep .dialogSuccess {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 58px 118px 75px;
  }
  img {
    margin: 0 auto;
  }
  p {
    font-size: 26px;
    font-weight: 200;
    line-height: 43px;
    color: #2a50b7;
    text-align: center;
  }
}
.register {
  .title {
    margin-bottom: 22px;
    p {
      font-size: 44px;
      line-height: 61px;
    }
  }
  p {
    line-height: 30px;
  }
  .inputBox {
    width: 420px;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 24px;
    input {
      width: 60px;
      font-size: 44px;
      font-weight: 200;
      line-height: 80px;
      color: #000000;
      text-align: center;
      border: 1px solid #2a50b7;
      border-radius: 12px;
    }
    .codeError {
      border: 1px solid #dd4040;
    }
  }
  .backBox {
    display: flex;
    font-size: 14px;
    line-height: 30px;
    color: #dd4040;
    justify-content: space-between;
  }
  .back {
    font-size: 14px;
    line-height: 30px;
    color: #2a50b7;
    cursor: pointer;
  }
  .try {
    line-height: 30px;
    color: #2a50b7;
    cursor: pointer;
  }
}
.setPassword,
.loginPassword {
  .title {
    div {
      font-size: 27px;
      line-height: 61px;
    }
    p {
      font-size: 44px;
      line-height: 61px;
    }
  }
  .user {
    display: inline-block;
    padding: 10px 25px 10px 15px;
    background: #f6f6f6;
    border-radius: 12px;
    margin-top: 15px;
    div {
      display: flex;
      align-items: center;
      span {
        font-size: 22px;
        line-height: 31px;
        color: #000000;
      }
    }
  }
  .tips {
    display: flex;
    align-items: center;
    padding: 10px 25px 10px 15px;
    background: #f6f6f6;
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
    img {
      width: 18px;
      height: 18px;
      margin-right: 9px;
    }
    span {
      line-height: 22px;
      color: #666666;
    }
  }
  .lable {
    line-height: 22px;
    color: #666666;
    margin-top: 11px;
    margin-bottom: 22px;
  }
  ::v-deep .el-input {
    .el-input__inner {
      width: 452px;
      border: 1px solid #999999;
      border-radius: 12px;
      height: 62px;
      font-size: 26px;
      line-height: 43px;
      font-weight: 200;
      opacity: 1;
    }
    .el-input__inner::placeholder {
      color: #dddddd;
    }
  }
  .btn {
    width: 452px;
    line-height: 62px;
    background: #63c918;
    border: 1px solid #dddddd;
    border-radius: 31px;
    text-align: center;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
  }
  .disabled {
    background: #dddddd;
    color: #ffffff;
    cursor: not-allowed;
  }
  .back {
    font-size: 14px;
    line-height: 30px;
    color: #2a50b7;
    cursor: pointer;
    margin-top: 20px;
  }
  .backBox {
    display: flex;
    justify-content: space-between;
    p {
      margin-top: 20px;
      line-height: 22px;
      color: #666666;
      span {
        color: #2a50b7;
        cursor: pointer;
      }
    }
  }
}
</style>