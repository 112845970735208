<template>
  <div class="product">
    <div class="title">产品管理</div>
    <el-button type="primary">新增</el-button>
    <el-table
      :data="productList"
      style="width: 100%"
      :header-cell-style="{ background: '#f8f8f9' }"
    >
      <el-table-column
        prop="id"
        label="ID"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="产品名称"
        width="190"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="code"
        label="产品编码"
        width="140"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="产品类型"
        width="190"
        align="center"
      ></el-table-column>
      <el-table-column label="上下架" align="center" width="140">
        <template slot-scope="scope">
          <p v-if="scope.row.shelf" class="success">上架</p>
          <p v-else class="error">下降</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="stateName"
        label="状态"
        width="140"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="users"
        label="适用用户"
        width="140"
        align="center"
      ></el-table-column>
      <el-table-column label="排序" align="center" width="240">
        <template slot-scope="scope">
          <el-input-number
            v-model="scope.row.sort"
            @change="handleChange(scope.row)"
            :min="0"
            label="描述文字"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="420">
        <template slot-scope="scope" class="operate">
          <el-button
            @click="offShelf(scope.row)"
            v-if="scope.row.shelf"
            type="text"
            >下架</el-button
          >
          <el-button v-else type="text" @click="grounding(scope.row)"
            >上架</el-button
          >
          <span class="line">|</span
          ><el-button @click="edit(scope.row)" type="text">编辑</el-button>
          <span class="line">|</span>
          <el-button type="text" @click="setFile(scope.row)"
            >设置上传文件</el-button
          >
          <span class="line">|</span>
          <el-button type="text" @click="setPackage(scope.row)"
            >设置材料包</el-button
          >
          <span class="line">|</span>
          <el-button type="text" @click="setMenu(scope.row)"
            >设置菜单</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page.pageSize"
      layout="total, prev, pager, next, sizes"
      :total="total"
      background
    >
    </el-pagination>
    <el-dialog
      title="产品设置"
      :close-on-click-modal="false"
      :visible.sync="dialogProductSet"
      width="1080"
    >
      <el-form
        :inline="true"
        :model="productInfo"
        label-width="100px"
        class="productForm"
        :rules="$rules.productForm"
        ref="productForm"
      >
        <el-form-item label="产品名称" prop="name">
          <el-input v-model="productInfo.name" placeholder="请输入"></el-input>
          <p>请输入简单的产品名称</p>
        </el-form-item>
        <el-form-item label="产品类型" prop="type">
          <el-select v-model="productInfo.type" placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :label="item.text"
              :value="item.keyCode"
            ></el-option>
          </el-select>
          <p>请在技术人员协助下填写正确的内容。</p>
        </el-form-item>
        <el-form-item label="产品图片" class="is-required">
          <div class="aupload">
            <el-upload
              ref="uploadFile"
              class="list"
              action="#"
              :class="{ disabled: uploadDisabled }"
              :on-remove="uploadRemove"
              :file-list="fileList"
              :http-request="httpRequest"
              name="upfile"
              :auto-upload="true"
              list-type="picture-card"
              :multiple="false"
              :limit="1"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="一句话简介" prop="des">
          <el-input
            type="textarea"
            maxlength="100"
            v-model="productInfo.des"
            placeholder="请输入"
          ></el-input>
          <p>请输入一句话简介100字以内。</p>
        </el-form-item>
        <el-form-item label="产品简介" class="is-required">
          <quillEditor
            class="editor"
            v-model="productInfo.introduction"
          ></quillEditor>
          <p>请输入2000字以内的产品简介。</p>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-select
            class="stateSelect"
            v-model="productInfo.state"
            placeholder="请选择"
          >
            <el-option
              v-for="item in stateList"
              :label="item.text"
              :value="item.keyCode"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogProductSet = false">取 消</el-button>
        <el-button class="submit" type="primary" @click="productSetSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  components: { quillEditor },
  data() {
    return {
      productList: [
        {
          id: "1",
          name: "标题标题标题",
          code: "cp0000001",
          type: "外资公司设立",
          shelf: 0,
          stateName: "敬请期待",
          users: "全部用户",
          sort: "",
        },
      ],
      page: {
        currentPage: 1,
        pageSize: 40,
      },
      total: 0,
      dialogProductSet: true,
      productInfo: {
        name: "11",
        type: "1",
        img: "",
        des: "22",
        introduction: "",
        state: "2",
      },
      typeList: [],
      stateList: [],
      action: "",
      flag: false,
      formData: new window.FormData(),
      fileList: [],
      uploadDisabled: false,
    };
  },
  methods: {
    uploadRemove(file, fileList) {
      this.flag = false;
      this.fileList = [];
      this.uploadDisabled = false;
      this.productInfo.img = "";
      this.formData.delete("productImage");
    },
    httpRequest(val) {
      if (this.flag) {
        if (this.fileList.length == 0) {
          this.$message.error("请上传产品图片！");
          this.flag = false;
          this.fileList = [];
          this.uploadDisabled = false;
          return;
        }
        this.formData = new window.FormData();
        this.formData.append("productImage", val.file);
        val.file.imgid = "productImage";
        // this.$api.Login.multifileUpload(this.formData, (result) => {
        //   if (result.code == "200") {
        //     this.flag = false;
        //     let ip = window.location.href.split("#")[0];
        //     if (result.productImage !== undefined) {
        //       this.productConfigForm.productImage = `${ip}api/File/fileDisplay?sftpPath=${result.productImage}`;
        //       sessionStorage.setItem(
        //         "productImage",
        //         JSON.stringify(result.productImage)
        //       );
        //     }
        //     if (this.isRevise) {
        //       this.getOptionalProductUpdate();
        //     } else {
        //       this.getOptionalProductInsert();
        //     }
        //   } else {
        //     this.flag = false;
        //     this.fileList = [];
        //     this.uploadDisabled = false;
        //     this.$message({
        //       showClose: true,
        //       message: result.message,
        //       type: "error",
        //     });
        //   }
        // });
      } else {
        this.uploadDisabled = true;
        const isJPG =
          val.file.type === "image/jpg" ||
          val.file.type === "image/jpeg" ||
          val.file.type === "image/png";
        if (!isJPG) {
          this.$message.error("仅支持jpg，png格式的图片！");
          this.uploadDisabled = false;
          this.fileList = [];
          return false;
        }
        this.fileList[0] = val.file;
      }
    },
    productSetSubmit() {
      this.flag = true;
      this.$refs.productForm.validate((val) => {
        if (val) {
          if (this.fileList.length == 0) {
            this.$message.error("请上传产品图片！");
            return;
          }
          if (!this.productInfo.introduction) {
            this.$message.error("请输入产品简介！");
            return
          }
          this.dialogProductSet = false;
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    setMenu(val) {
      console.log(val);
    },
    setPackage(val) {
      console.log(val);
    },
    setFile(val) {
      console.log(val);
    },
    edit(val) {
      console.log(val);
    },
    offShelf(val) {
      this.$confirm("是否确认下架", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          console.log(val);
        })
        .catch(() => {});
    },
    grounding(val) {
      this.$confirm("是否确认上架", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          console.log(val);
        })
        .catch(() => {});
    },
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px;
  margin-bottom: 30px;
}
.el-table {
  margin-top: 30px;
  .error {
    color: #ff9292;
  }
  .success {
    color: #71e2a3;
  }
}
.line {
  color: #409eff;
  margin: 0 5px;
}
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.productForm {
  p {
    line-height: 1;
    font-size: 13px;
    margin-top: 10px;
    color: #aaa;
  }
  .el-input {
    width: 400px;
  }
  .el-select {
    width: 300px;
  }
  .stateSelect {
    width: 100px;
  }
  .el-textarea {
    width: 300px;
  }
}
.aupload {
  width: 500px;
}
.editor {
  width: 800px;
}
::v-deep .el-dialog__footer {
  text-align: center;
  .submit {
    margin-left: 40px;
  }
}
</style>