<template>
  <div class="user">
    <div class="title">用户管理</div>
    <div class="header">
      <el-card class="box-card">
        <div>注册用户数</div>
        <p>{{ info.num }}</p>
      </el-card>
      <el-card class="box-card">
        <div>web注册用户数</div>
        <p>{{ info.num1 }}</p>
      </el-card>
      <el-card class="box-card">
        <div>MYEG注册用户数</div>
        <p>{{ info.num2 }}</p>
      </el-card>
    </div>
    <el-card class="box-card">
      <el-form :inline="true" :model="userForm">
        <el-form-item label="客户编号">
          <el-input
            v-model="userForm.customerID"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="国籍区号">
          <el-select v-model="userForm.areaCode" placeholder="请选择">
            <el-option
              v-for="item in areaCodeList"
              :label="item.text"
              :value="item.keyCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="userForm.phone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="userForm.email" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="渠道">
          <el-select v-model="userForm.channel" placeholder="请选择">
            <el-option
              v-for="item in channelList"
              :label="item.text"
              :value="item.keyCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注姓名">
          <el-input v-model="userForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="备注企业">
          <el-input
            v-model="userForm.enterprise"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注联系">
          <el-input v-model="userForm.contact" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="备注行业">
          <el-input v-model="userForm.industry" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="备注其他">
          <el-input v-model="userForm.other" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="userForm.state" placeholder="请选择">
            <el-option
              v-for="item in stateList"
              :label="item.text"
              :value="item.keyCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册时间">
          <el-date-picker
            v-model="userForm.registerTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="getTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="primary" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div>
      <el-table
        :data="userList"
        style="width: 100%"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column
          prop="id"
          label="ID"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customerID"
          width="100"
          label="客户编号"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="areaCode"
          label="国际区号"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="登录手机号"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="email"
          label="登录邮箱"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="channel"
          label="渠道来源"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="registerTime"
          label="注册时间"
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column label="客户备注" align="center" width="250">
          <template slot-scope="scope">
            <div class="notes">
              <p>姓名：{{ scope.row.name }}</p>
              <p>电话：{{ scope.row.contact }}</p>
              <p>企业：{{ scope.row.enterprise }}</p>
              <p>行业：{{ scope.row.industry }}</p>
              <p>其他：{{ scope.row.other }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="哈希" align="center" width="250">
          <template slot-scope="scope">
            <div class="hashBox">
              <p>{{ scope.row.hash }}</p>
              <span class="copy" @click="copy(scope.row.hash)">复制</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="100">
          <template slot-scope="scope">
            <p v-if="scope.row.state" class="success">正常</p>
            <p v-else class="error">禁用</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template slot-scope="scope">
            <el-button
              @click="disable(scope.row)"
              v-if="scope.row.state"
              type="text"
              >禁用</el-button
            >
            <el-button v-else type="text" @click="enable(scope.row)"
              >启用</el-button
            >
            <el-button @click="edit(scope.row)" type="text">编辑</el-button>
            <el-button type="text" @click="note(scope.row)">客户备注</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, sizes"
        :total="total"
        background
      >
      </el-pagination>
    </div>
    <el-dialog
      title="编辑"
      :close-on-click-modal="false"
      :visible.sync="dialogEdit"
      width="460px"
    >
      <el-form
        :inline="true"
        :model="editInfo"
        label-width="100px"
        class="editForm"
        :rules="rules"
        ref="editForm"
      >
        <el-form-item label="客户编号">
          <el-input v-model="editInfo.customerID" disabled></el-input>
        </el-form-item>
        <el-form-item label="国际区号">
          <el-select v-model="editInfo.areaCode" placeholder="请选择">
            <el-option
              v-for="item in areaCodeList"
              :label="item.text"
              :value="item.keyCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录手机号" prop="phone">
          <el-input v-model="editInfo.phone"></el-input>
        </el-form-item>
        <el-form-item label="登录邮箱" prop="email">
          <el-input v-model="editInfo.email"></el-input>
        </el-form-item>
        <el-form-item label="渠道来源">
          <el-select v-model="editInfo.channel" placeholder="请选择">
            <el-option
              v-for="item in channelList"
              :label="item.text"
              :value="item.keyCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重置密码">
          <el-input type="password" v-model="editInfo.newPassword"></el-input>
          <p class="error">如无需重置密码，请留空，不要输入。</p>
        </el-form-item>
        <el-form-item label="哈希">
          <p>
            {{ editInfo.hash
            }}<el-button
              style="margin-left: 10px"
              type="text"
              @click="copy(editInfo.hash)"
              >复制</el-button
            ><el-button type="text" @click="toHash">查看</el-button>
          </p>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogEdit = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="客户备注"
      :close-on-click-modal="false"
      :visible.sync="dialogNotes"
      width="580px"
    >
      <div class="tips">
        <i class="el-icon-warning"></i>
        用于业务人员简要记录客户基本情况。
      </div>
      <el-form
        :inline="true"
        :model="noteInfo"
        label-width="110px"
        class="noteForm"
      >
        <el-form-item label="客户姓名">
          <el-input v-model="noteInfo.name" placeholder="请输入"></el-input>
          <p>记录客户的姓名或称呼，例：王先生等。</p>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input v-model="noteInfo.contact" placeholder="请输入"></el-input>
          <p>记录客户的便捷的联系方式，例如：微信号qdds</p>
        </el-form-item>
        <el-form-item label="企业简称或全称">
          <el-input
            v-model="noteInfo.enterprise"
            placeholder="请输入"
          ></el-input>
          <p>例：青岛东石</p>
        </el-form-item>
        <el-form-item label="行业类型">
          <el-input v-model="noteInfo.industry" placeholder="请输入"></el-input>
          <p>例：餐饮供应链</p>
        </el-form-item>
        <el-form-item label="其他备注">
          <el-input
            type="textarea"
            v-model="noteInfo.other"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogNotes = false">取 消</el-button>
        <el-button type="primary" @click="noteSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    let validatePhone = (rule, value, callback) => {
      let phone = new RegExp("^[1][0-9]{10}$"); // 手机号
      if (value !== "" && !phone.test(value)) {
        return callback(new Error("请输入正确格式的手机号"));
      } else {
        callback();
      }
    };
    let validateEmail = (rule, value, callback) => {
      let email = new RegExp(
        "^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(.[a-zA-Z0-9-]+)*.[a-zA-Z0-9]{2,6}$"
      );
      if (value !== "" && !email.test(value)) {
        return callback(new Error("请输入正确格式的邮箱"));
      } else {
        callback();
      }
    };
    return {
      info: {
        num: 266,
        num1: 266,
        num2: 266,
      },
      userForm: {
        customerID: "",
        areaCode: "",
        phone: "",
        email: "",
        channel: "",
        name: "",
        enterprise: "",
        contact: "",
        industry: "",
        other: "",
        state: "",
        registerTime: [],
      },
      areaCodeList: [],
      channelList: [],
      stateList: [],
      userList: [
        {
          id: "1",
          customerID: "kh0000001",
          areaCode: "+86",
          phone: "18888888888",
          email: "123456789@qq.com",
          channel: "web",
          registerTime: "2023-9-12 10:03:11",
          name: "姓名",
          contact: "18888888888",
          enterprise:
            "王小宝的奶茶王小宝的奶茶王小宝的奶茶王小宝的奶茶王小宝的奶茶王小宝的奶茶",
          industry: "餐饮",
          other: "融资感兴趣",
          hash: "188888888881888888888818888888888",
          state: 1,
        },
      ],
      page: {
        currentPage: 1,
        pageSize: 40,
      },
      total: 0,
      dialogEdit: false,
      editInfo: {
        customerID: "",
        areaCode: "",
        email: "",
        channel: "",
        newPassword: "",
        hash: "",
      },
      editInfo: {
        customerID: "",
        areaCode: "",
        email: "",
        channel: "",
        newPassword: "",
        hash: "",
      },
      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
        email: [{ validator: validateEmail, trigger: "blur" }],
      },
      dialogNotes: false,
      noteInfo: {
        name: "",
        contact: "",
        enterprise: "",
        industry: "",
        other: "",
      },
    };
  },
  methods: {
    note(val) {
      this.noteInfo = { ...val };
      this.dialogNotes = true;
    },
    noteSubmit() {
      this.dialogNotes = false;
      console.log(this.noteInfo);
    },
    edit(val) {
      this.editInfo = { ...val };
      this.dialogEdit = true;
    },
    toHash() {
      window.open(`https://test-explorer.zetrix.com/tx/${this.editInfo.hash}`);
    },
    editSubmit() {
      this.$refs.editForm.validate((val) => {
        if (val) {
          if (!this.editInfo.phone && !this.editInfo.email) {
            this.$message.warning("登录手机号和登录邮箱不可同时为空！");
            return;
          }
          this.dialogEdit = false;
          console.log(this.editInfo);
        }
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    disable(val) {
      this.$confirm("是否确认禁用", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          console.log(val);
        })
        .catch(() => {});
    },
    enable(val) {
      this.$confirm("是否确认启用", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          console.log(val);
        })
        .catch(() => {});
    },
    copy(val) {
      let cInput = document.createElement("input");
      cInput.value = val;
      document.body.appendChild(cInput);
      cInput.select();
      document.execCommand("copy");
      this.$message({
        type: "success",
        message: "复制成功",
      });
      document.body.removeChild(cInput);
    },
    search() {
      console.log(this.userForm);
    },
    getTime() {
      console.log(this.userForm.registerTime);
      let time = this.userForm.registerTime[1].split(" ")[0] + " 23:59:59";
      this.userForm.registerTime = [this.userForm.registerTime[0], time];
      console.log(this.userForm.registerTime);
    },
    reset() {
      this.userForm.customerID = "";
      this.userForm.areaCode = "";
      this.userForm.phone = "";
      this.userForm.email = "";
      this.userForm.channel = "";
      this.userForm.name = "";
      this.userForm.enterprise = "";
      this.userForm.contact = "";
      this.userForm.industry = "";
      this.userForm.other = "";
      this.userForm.state = "";
      this.userForm.registerTime = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px;
  margin-bottom: 30px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .box-card {
    width: 500px;
    height: 130px;
    div {
      margin-bottom: 15px;
    }
    p {
      font-size: 32px;
    }
  }
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
.el-date-editor .el-range-separator {
  width: 6%;
}
.el-table {
  margin-top: 30px;
  .error {
    color: #ff9292;
  }
  .success {
    color: #71e2a3;
  }
}
.notes {
  width: 100%;
  overflow-x: auto;
  p {
    text-align: start;
    padding: 0 10px;
    white-space: nowrap;
  }
}
.hashBox {
  display: flex;
  justify-content: center;
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
  }
  span {
    color: #409eff;
    cursor: pointer;
  }
}
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.editForm {
  .el-select {
    width: 150px;
  }
  p {
    width: 250px;
  }
  .error {
    color: #ff9292;
  }
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.tips {
  background: #efefef;
  line-height: 40px;
  padding: 0 10px;
  margin-bottom: 25px;
  font-size: 13px;
  i {
    color: #409eff;
    font-size: 14px;
  }
}
.noteForm {
  p {
    line-height: 1;
    font-size: 13px;
    margin-top: 10px;
    color: #aaa;
  }
  .el-input {
    width: 200px;
  }
  .el-textarea {
    width: 400px;
  }
}
</style>